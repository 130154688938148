<template>
    <div class="shadow-lg p-3 mb-5 mt-5 container">
        <h1>Schützenbuch <img
            src="/Bilder/book.png"
            width="100"
            height="100"
            class="rounded-circle"
        /></h1>



        <div
            class="col-sm-10 mb-5 pt-3"
            v-html="getDSGVOText">
        </div>
        <br>

    </div>
</template>

<script>
// import {API} from "../../plugins/axios";

export default {
    name: "Datenschutz",

    data: () => ({

    }),
    computed: {
      getDSGVOText() {
        return this.$store.state.GeneralApp.TextDSGVO;
      },
    },

    mounted () {

    },
    created() {

    },

    methods:{


    },



}
</script>

<style scoped>

</style>
